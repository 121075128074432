/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'

import Helmet from '../../components/HelmetHelper'
import Container from '../../components/Container'
import Text, { TEXT_SIZE_SMALL } from '../../components/content/Text'
import {
	THEME_TAUPE,
	THEME_GREEN,
	THEME_WHITE,
	colors,
	media,
} from '../../constants'
import Slide, {
	SLIDE_LAYOUT_MOCK_FULL_SCREEN,
	SLIDE_LAYOUT_FULL_BLEED_IMAGE,
} from '../../components/Slide'
import {
	LayoutSplitHeading,
	LayoutIndented,
	LayoutCol2,
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_MEDIUM,
} from '../../components/content/Layout'
import FullBleedImage from '../../components/content/FullBleedImage'
import { InlineLink, StandaloneLink } from '../../components/content/Link'
import { provideAccentTheme } from '../../context/AccentThemeContext'
import Highlight from '../../components/content/Highlight'

import Showcase, {
	SHOWCASE_LAYOUT_1UP,
	SHOWCASE_LAYOUT_2UP,
} from '../../components/content/Showcase'
import VideoLoop, {
	VIDEO_CLIP_AUTOPLAY,
} from '../../components/content/VideoLoop'

import { caseStudyLinkData } from '../../workData'

const nextPage = caseStudyLinkData.unqork.next

const UnqorkPage = ({ accentTheme }) => {
	const data = useStaticQuery(graphql`
		query {
			workflowAbstract: file(
				relativePath: { eq: "unqork/unqork-workflow-abstract@2x.png" }
			) {
				...ImageFluidFull
			}
			workflowUI: file(
				relativePath: { eq: "unqork/unqork-workflow-ui@2x.png" }
			) {
				...ImageFluidFull
			}
			dsMosaic: file(relativePath: { eq: "unqork/unqork-ds-mosaic@2x.png" }) {
				...ImageFluidFull
			}

			dsDoc01: file(relativePath: { eq: "unqork/unqork-ds-doc-01@2x.png" }) {
				...ImageFluidFull
			}

			dsDoc02: file(relativePath: { eq: "unqork/unqork-ds-doc-02@2x.png" }) {
				...ImageFluidFull
			}

			docsButton: file(
				relativePath: { eq: "unqork/unqork-docs-button@2x.png" }
			) {
				...ImageFluidFull
			}

			workspacesDS: file(
				relativePath: { eq: "unqork/unqork-workspaces-ds@2x.png" }
			) {
				...ImageFluidFull
			}

			workspacesUI: file(
				relativePath: { eq: "unqork/unqork-workspaces-ui@2x.png" }
			) {
				...ImageFluidFull
			}

			canvas: file(relativePath: { eq: "unqork/unqork-canvas-modal@2x.png" }) {
				...ImageFluidFull
			}

			canvasModal: file(relativePath: { eq: "unqork/unqork-canvas@2x.png" }) {
				...ImageFluidFull
			}
		}
	`)

	return (
		<div>
			<Helmet>
				<title>Unqork</title>
				<meta content="Unqork Case Study" property="og:title" />
				<meta content="Unqork Case Study" name="twitter:title" />
				<meta
					property="og:description"
					content="A design system for the no-code revolution."
				/>
				<meta
					name="description"
					content="A design system for the no-code revolution."
				/>
				<meta
					content="https://freeassociation.com/share/unqork.png"
					property="og:image"
				/>
				<meta
					content="https://freeassociation.com/share/unqork.png"
					property="twitter:image:src"
				/>
			</Helmet>
			<Slide layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}>
				<Container>
					<LayoutIndented>
						<Text>
							<h1 className="--eyebrow">Unqork</h1>
							<p className="--headline">
								A design system for the no-code revolution.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutSplitHeading title="Overview">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<p>
									Unqork is a visual, no-code platform that helps large
									enterprises build complex custom software. After four years of
									exponential growth, the startup — now a $2 billion tech
									unicorn — brought in Free Association to co-create a
									comprehensive design system to unify and govern all dimensions
									of the platform at scale, eliminating one-off solutions,
									updates, disjointed user experiences, and technical debt. In
									addition to supporting the design system rollout, we’ve
									recently been partnering with teams across Unqork to optimize
									key areas of the platform experience.
								</p>
							</Text>
						</LayoutSplitHeading>
						<LayoutSplitHeading title="Services">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<ul className="--unstyled-mediumUp --col-2">
									<li>Product Leadership</li>
									<li>Strategic Planning</li>
									<li>Project Management</li>
									<li>Information Architecture</li>
									<li>User Experience Design</li>
									<li>UI &amp; Visual Design</li>
									<li>Brand Systems &amp; Guidelines</li>
									<li>Technical Strategy</li>
								</ul>
							</Text>
						</LayoutSplitHeading>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
				style={{
					[media.small]: {
						marginBottom: '3.5rem',
					},
				}}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						fluidImageData={data.workflowAbstract.childImageSharp.fluid}
					/>
				</div>
			</Slide>
			<Slide prefferedTheme={THEME_TAUPE}>
				<Container>
					<Showcase
						images={[data.workflowUI.childImageSharp.fluid]}
						layout={SHOWCASE_LAYOUT_1UP}
					/>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p>
								Our goal was to create a robust, flexible system of UI building
								blocks that could dramatically increase the impact, efficiency
								and velocity of internal design and engineering teams tasked
								with shaping the future of the platform.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						fluidImageData={data.dsMosaic.childImageSharp.fluid}
					/>
				</div>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									We collaborated with teams across the platform to plan, design
									and implement a robust product design system that would meet
									the needs of a rapidly-growing platform and organization.
								</p>
							</Text>
						</LayoutIndented>
						<LayoutCol2 verticalCadenceSize={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
							<Highlight title="Two-Tiered Design System">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p class="--grey">
										We architected a two-tiered design system that houses global
										brand standards and elements alongside local component
										libraries that give product teams the flexibility and
										autonomy to extend the system to application-specific use
										cases.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Design &amp; Development Parity">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p class="--grey">
										To ensure complete alignment between design and development,
										we created a robust Figma library in parallel with a
										corresponding React component library. As a result, Unqork
										is able to more efficiently release new features and improve
										the overall consistency of the platform.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Centralized Platform">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p class="--grey">
										We created a centralized internal platform to house all
										documentation, usage guidelines, design principles, and
										resources. Live embedded Figma designs and coded components
										via Storybook automate content updates and ensure an always
										up-to-date view.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Rollout &amp; Launch">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p class="--grey">
										Through the course of our work, we also helped shape new
										internal processes and capabilities, ensuring teams were set
										up for success by streamlining design tooling, conducting
										onboarding sessions, and defining best practices.
									</p>
								</Text>
							</Highlight>
						</LayoutCol2>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
				style={{
					[media.small]: {
						marginBottom: '3.5rem',
					},
				}}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<Container maxWidth="82.5rem">
						<Showcase
							images={[
								data.dsDoc01.childImageSharp.fluid,
								data.dsDoc02.childImageSharp.fluid,
							]}
							layout={SHOWCASE_LAYOUT_2UP}
							reverseStagger={true}
						/>
					</Container>
				</div>
			</Slide>

			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
						marginBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<VideoLoop width={2540} height={1440} mode={VIDEO_CLIP_AUTOPLAY}>
						<source src="/Unqork-Storybook.mov" />
					</VideoLoop>
				</Container>
			</Slide>
			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<blockquote>
								<p className="--large">
									“Free Association has set a new standard for third-party
									partners. We’re so grateful for their intellect, craft,
									authenticity and magic.”
								</p>
								<footer>
									<p className="--small">
										<strong>Dafna Alsheh</strong>
										<br />
										VP, Design
									</p>
								</footer>
							</blockquote>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p>
								Given the depth and complexity of the platform, we built a
								family of application-specific component libraries that would
								give different teams the flexibility and autonomy to extend and
								adapt the system while still maintaining consistency.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<div
					css={{
						background: '#F9FAFB',
					}}
				>
					<FullBleedImage
						fluidImageData={data.workspacesDS.childImageSharp.fluid}
					/>
				</div>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p>
								The new design system provided an opportunity to rebuild and
								streamline core facets and functionality of the platform from
								the ground-up, including Workspaces, Workflow, Module Editor,
								and Component Configuration.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						marginBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<Showcase
						images={[data.workspacesUI.childImageSharp.fluid]}
						layout={SHOWCASE_LAYOUT_1UP}
					/>
				</Container>
			</Slide>
			<Slide prefferedTheme={THEME_TAUPE}>
				<Container maxWidth="75rem">
					<Showcase
						images={[
							data.canvas.childImageSharp.fluid,
							data.canvasModal.childImageSharp.fluid,
						]}
						layout={SHOWCASE_LAYOUT_2UP}
					/>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--eyebrow">Next</p>
							<p className="--headline">
								<StandaloneLink to={nextPage.to}>
									{nextPage.title}
								</StandaloneLink>
							</p>
							<p className="--small">
								<InlineLink to="/work">All Work</InlineLink>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
		</div>
	)
}

export default provideAccentTheme(UnqorkPage, THEME_GREEN)
